import HttpClient, { HttpClientInterface } from '../../../../../infra/http-client/HTTPClient';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

export interface HeaderSearchRepositoryInterface {
  getSearchAutocomplete: (search: PTZHeaderTypes.SearchGetRequest) => Promise<PTZHeaderTypes.SearchAPIResponse>;
  deleteSearchHistory: (search: PTZHeaderTypes.SearchDeleteRequest) => Promise<null>;
}

class HeaderSearchRepository implements HeaderSearchRepositoryInterface {
  private http: HttpClientInterface<PTZHeaderTypes.SearchAPIResponse | null> = new HttpClient();
  private fixedHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  getSearchAutocomplete = async ({
    value,
    baseUrl,
    clientId,
    token,
    historySize,
    productsSize,
    termsSize,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.SearchGetRequest): Promise<PTZHeaderTypes.SearchAPIResponse> => {
    const customerIdQuery = clientId && !!token ? `customerId=${clientId}` : '';
    const historySizeQuery = historySize ? `&historySize=${historySize}` : '';
    const productsSizeQuery = productsSize ? `&productsSize=${productsSize}` : '';
    const termsSizeQuery = termsSize ? `&termsSize=${termsSize}` : '';
    const url = `${baseUrl}/api/v3/search/autocomplete/${value}?${customerIdQuery}${historySizeQuery}${productsSizeQuery}${termsSizeQuery}`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ? { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response;
    try {
      response = await this.http.request({ url, headers });
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };

  deleteSearchHistory = async ({
    historyId,
    baseUrl,
    token,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.SearchDeleteRequest): Promise<null> => {
    const url = `${baseUrl}/api/v3/search/history/${historyId}`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response;
    try {
      response = await this.http.request({ url, headers, method: "DELETE" });
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };
}

export default HeaderSearchRepository;
