type HTTPBody = {
 [x: string]: string | number | boolean | null | HTTPBody
}

type Params = {
  url: string,
  method?: string,
  body?: HTTPBody,
  headers?: {
    [key: string]: string;
  },
}

export interface HttpClientInterface<T> {
  request: (params: Params) => Promise<T>;
}

class HTTPClient {
  request = async (params: Params) => {
    const httpMethod = params.method !== null ? params.method : 'GET'
    const data = params.body !== null ? params.body : null

    const response = await fetch(`${params.url}`, {
      method: httpMethod,
      headers: params.headers,
      body: JSON.stringify(data)
    })

    if (response.status === 200) {
      const result = await response.json()
      return result
    }

    if (!response.ok) {
      const status = response.status;
      if (status === 401 || status === 403) {
        throw new Error(`Unauthorized. Status: ${status}`);
      } else {
        throw new Error('Request failed.');
      }
    }

    return {}
  }
}

export default HTTPClient
