import HttpClient from '../../../../../infra/http-client/HTTPClient';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

export interface HeaderBagRepositoryInterface {
  getBagCount: (params: PTZHeaderTypes.BagGetRequest) => Promise<PTZHeaderTypes.BagCountAPIResponse>;
  getBagSummary: (params: PTZHeaderTypes.BagGetRequest) => Promise<PTZHeaderTypes.BagSummaryAPIResponse>;
  postBagProduct: (params: PTZHeaderTypes.BagPostRequest) => Promise<PTZHeaderTypes.BagProductAPIResponse>;
  deleteBagProduct: (params: PTZHeaderTypes.BagDeleteRequest) => Promise<PTZHeaderTypes.BagProductAPIResponse>;
}

class HeaderBagRepository implements HeaderBagRepositoryInterface {
  private http = new HttpClient();
  private fixedHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  getBagCount = async ({
    baseUrl,
    token,
    bagId,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.BagGetRequest): Promise<PTZHeaderTypes.BagCountAPIResponse> => {
    const url = `${baseUrl}/api/v3/shoppingCart/${bagId}/count`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response: PTZHeaderTypes.BagCountAPIResponse;
    try {
      response = await this.http.request({ url, headers });
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };

  getBagSummary = async ({
    baseUrl,
    token,
    bagId,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.BagGetRequest): Promise<PTZHeaderTypes.BagSummaryAPIResponse> => {
    const url = `${baseUrl}/api/v3/shoppingCart/${bagId}/summary`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response: PTZHeaderTypes.BagSummaryAPIResponse;
    try {
      response = await this.http.request({ url, headers });
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };

  postBagProduct = async ({
    baseUrl,
    token,
    bagId,
    sku,
    quantity,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.BagPostRequest): Promise<PTZHeaderTypes.BagProductAPIResponse> => {
    const url = `${baseUrl}/api/v3/shoppingCart/${bagId}/item`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response: PTZHeaderTypes.BagProductAPIResponse;
    try {
      response = await this.http.request({ url, headers, method: "POST", body: {sku, quantity}});
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };

  deleteBagProduct = async ({
    baseUrl,
    token,
    bagId,
    sku,
    quantity,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.BagPostRequest): Promise<PTZHeaderTypes.BagProductAPIResponse> => {
    const url = `${baseUrl}/api/v3/shoppingCart/${bagId}/item/${sku}`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response: PTZHeaderTypes.BagProductAPIResponse;
    try {
      response = await this.http.request({ url, headers, method: "DELETE", body: {quantity}});
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };
}

export default HeaderBagRepository;
