import { debounce, isOnIframe } from '../../utils/utils';
import { PTZHeaderDrawerBagConfig } from './components/ptz-header-drawer-bag/types/ptz-header-drawer-bag.enums';
import { PTZHeaderDrawerBagTypes } from './components/ptz-header-drawer-bag/types/ptz-header-drawer-bag.types';
import { PTZHeaderMenuDrawerTypes } from './components/ptz-header-menu-drawer/types/ptz-header-menu-drawer.types';
import HeaderBagRepository from './data/repositories/ptz-header-bag/ptz-header-bag.repository';
import HeaderFavoriteRepository from './data/repositories/ptz-header-favorite/ptz-header-favorite.repository';
import HeaderMenuDepartmentsRepository from './data/repositories/ptz-header-menu-departments/ptz-header-menu-departments.repository';
import HeaderSearchRepository from './data/repositories/ptz-header-search/ptz-header-search.repository';
import DeleteHeaderBagUseCase from './domain/usecase/ptz-header-bag/delete-header-bag.usecase';
import GetHeaderBagUseCase from './domain/usecase/ptz-header-bag/get-header-bag.usecase';
import PostHeaderBagUseCase from './domain/usecase/ptz-header-bag/post-header-bag.usecase';
import GetHeaderFavoriteUseCase from './domain/usecase/ptz-header-favorite/get-header-favorite.usecase';
import GetHeaderMenuDepartments from './domain/usecase/ptz-header-menu-departments/get-header-menu-departments.usecase';
import DeleteHeaderSearchUseCase from "./domain/usecase/ptz-header-search/delete-header-search.usecase";
import GetHeaderSearchUseCase from "./domain/usecase/ptz-header-search/get-header-search.usecase";
import { dataDepartments, institutionalDepartments, mainDepartments } from './mocks/menu.mock';
import { PTZHeaderConfig } from './types/ptz-header.enums';
import { PTZHeaderTypes } from "./types/ptz-header.types";

export default class HeaderPresenter {
  constructor(
    private baseUrl: string,
    private clientId: string,
    private bagId: string,
    private token: string,
    private isMobile: boolean,
    private setValueSearch: (value: string) => void,
    private recommendedList: PTZHeaderTypes.SearchAPIResponse,
    private setRecommendedList: (data: PTZHeaderTypes.SearchAPIResponse) => void,
    private setBagSummaryList: (data: PTZHeaderDrawerBagTypes.BagSummary) => void,
    private setHasErrorDrawer: (data: PTZHeaderDrawerBagTypes.BagError | null) => void,
    private setIsLoading: (data: PTZHeaderDrawerBagTypes.BagLoad | null) => void,
    private analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void,
    private tokenAuthErrorHandler: () => void,
  ) {}

  lastScrollTop = 0;
  private bagKey = 'bagCount';
  private favoriteKey = 'favoriteCount';
  private headerBagRepository = new HeaderBagRepository();
  private headerSearchRepository = new HeaderSearchRepository();
  private headerFavoriteRepository = new HeaderFavoriteRepository();
  private headerBagDeleteUseCase = new DeleteHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
  private headerBagPostUseCase = new PostHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
  private headerBagGetUseCase = new GetHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
  private headerSearchDeleteUseCase = new DeleteHeaderSearchUseCase(this.baseUrl, this.token, this.headerSearchRepository, this.tokenAuthErrorHandler);
  private headerSearchGetUseCase = new GetHeaderSearchUseCase(this.baseUrl, this.clientId, this.token, this.headerSearchRepository, this.tokenAuthErrorHandler);
  private headerFavoriteGetUseCase = new GetHeaderFavoriteUseCase(this.baseUrl, this.clientId, this.token, this.headerFavoriteRepository, this.tokenAuthErrorHandler);
  private headerMenuDepartmentsRepository = new HeaderMenuDepartmentsRepository();
  private headerMenuDepartmentsUseCase = new GetHeaderMenuDepartments(this.baseUrl, this.headerMenuDepartmentsRepository)

  getMethodConfigValues(token: string, bagId: string, clientId: string, baseUrl: string) {
    this.token = token;
    this.bagId = bagId;
    this.baseUrl = baseUrl;
    this.clientId = clientId;

    this.headerBagDeleteUseCase = new DeleteHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
    this.headerBagPostUseCase = new PostHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
    this.headerBagGetUseCase = new GetHeaderBagUseCase(this.baseUrl, this.bagId, this.token, this.headerBagRepository, this.tokenAuthErrorHandler);
    this.headerSearchDeleteUseCase = new DeleteHeaderSearchUseCase(this.baseUrl, this.token, this.headerSearchRepository, this.tokenAuthErrorHandler);
    this.headerSearchGetUseCase = new GetHeaderSearchUseCase(this.baseUrl, this.clientId, this.token, this.headerSearchRepository, this.tokenAuthErrorHandler);
    this.headerFavoriteGetUseCase = new GetHeaderFavoriteUseCase(this.baseUrl, this.clientId, this.token, this.headerFavoriteRepository, this.tokenAuthErrorHandler);
    this.headerMenuDepartmentsRepository = new HeaderMenuDepartmentsRepository();
    this.headerMenuDepartmentsUseCase = new GetHeaderMenuDepartments(this.baseUrl, this.headerMenuDepartmentsRepository)
  }

  private errorProductBagTracking(error_text: string) {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.ErrorDisplayed, {
      element: 'Header Sacola',
      error_id: null,
      error_title: null,
      error_text
    });
  }

  private productBagTracking(product: PTZHeaderDrawerBagTypes.Item, position: number, quantity: number, type: PTZHeaderTypes.AnalyticsTrackingKind): void {
    this.analyticsTrackingHandler(type, {
      element: 'Header Sacola',
      cart_id: this.bagId,
      product: {
        brand: `${product.brand}`,
        category: `${product.category}`,
        currency: "BRL",
        image_url: `${product.thumbnail}`,
        name: `${product.name}`,
        position: `${position}`,
        price: `${product.currentPrice}`,
        product_id: `${product.id}`,
        quantity: `${quantity}`,
        url: `${this.baseUrl}/${product.token}`,
        sku: `${product.sku}`,
        variationName: `${product.variationName}` || null
      }
    });
  }

  getBagSummary = async () => {
    try {
      const data = await this.headerBagGetUseCase.getBagSummary();
      const { result } = data;
      this.setBagSummaryList(result.bagSummary);
      this.setHasErrorDrawer(null);
      this.dispatchBagCountEvent();
    } catch (err) {
      this.setHasErrorDrawer({ type: PTZHeaderDrawerBagConfig.Error.Get, sku: null });
      this.errorProductBagTracking('Não foi possível carregar. Verifique sua internet e tente outra vez.');
    } finally {
      this.setIsLoading(null);
    }
  }

  private dispatchBagCountEvent = () => {
    const event = new CustomEvent('loadIconNotification', {
      detail: { kind: 'bag' }
    });
    document.dispatchEvent(event);
  }

  postBagProduct = async (sku: string, quantity: number, typeError: PTZHeaderDrawerBagTypes.Error, product: PTZHeaderDrawerBagTypes.Item, position: number) => {
    try {
      this.setIsLoading({ type: PTZHeaderDrawerBagConfig.Load.Update })
      await this.headerBagPostUseCase.postBagProduct(sku, quantity);
      this.setHasErrorDrawer(null);
      this.updateBagCountStorage(quantity, PTZHeaderDrawerBagConfig.Mode.Add);
      this.productBagTracking(product, position, quantity, PTZHeaderConfig.AnalyticsTrackingKind.ProductAdded);
      await this.getBagSummary();
    } catch (err) {
      this.setHasErrorDrawer({type: typeError, sku});
      this.errorProductBagTracking('Não foi possível alterar a quantidade deste produto. Tente novamente.');
    } finally {
      this.setIsLoading(null);
    }
  }

  deleteBagProduct = async (sku: string, quantity: number, typeError: PTZHeaderDrawerBagTypes.Error, product: PTZHeaderDrawerBagTypes.Item, position: number) => {
    try {
      this.setIsLoading({ type: PTZHeaderDrawerBagConfig.Load.Delete });
      await this.headerBagDeleteUseCase.deleteBagProduct(sku, quantity);
      this.setHasErrorDrawer(null);
      this.updateBagCountStorage(quantity, PTZHeaderDrawerBagConfig.Mode.Remove);
      this.productBagTracking(product, position, quantity, PTZHeaderConfig.AnalyticsTrackingKind.ProductRemoved);
      this.setIsLoading({ type: PTZHeaderDrawerBagConfig.Load.GetDelete });
      await this.getBagSummary();
    } catch (err) {
      this.setIsLoading({ type: PTZHeaderDrawerBagConfig.Load.Delete });
      if (typeError === PTZHeaderDrawerBagConfig.Error.Delete) {
        this.errorProductBagTracking('Não conseguimos excluir o produto. Vamos lá, tente de novo.');
      }
      if (typeError === PTZHeaderDrawerBagConfig.Error.Decrement) {
        this.errorProductBagTracking('Não conseguimos excluir o produto. Vamos lá, tente de novo.');
      }
      this.setHasErrorDrawer({ type: typeError, sku });
    } finally {
      this.setIsLoading(null);
    }
  }

  updateBagCountStorage = (quantity: number, mode: PTZHeaderDrawerBagTypes.Mode): void => {
    const itemKey = 'bagCount';
    const count = this.getCountFromCache(itemKey);

    switch (mode) {
      case PTZHeaderDrawerBagConfig.Mode.Add:
        window.sessionStorage.setItem(itemKey, JSON.stringify({ count: count + quantity }));
        break;
      case PTZHeaderDrawerBagConfig.Mode.Remove:
        window.sessionStorage.setItem(itemKey, JSON.stringify({ count: count - quantity }));
        break;
      default:
        window.sessionStorage.setItem(itemKey, JSON.stringify({ count }));
        break;
    }
  }

  getCountFromCache = (item: string): number | null => {
    const countSessionStorage = window.sessionStorage.getItem(item);

    if (!countSessionStorage) return null;

    const { count } = JSON.parse(countSessionStorage);
    return count;
  };

  getBagCountFromApi = async (): Promise<number> => {
    const data = await this.headerBagGetUseCase.getBagCount();
    const { count } = data;
    return count;
  };

  getFavoriteCountFromApi = async (): Promise<number> => {
    const data = await this.headerFavoriteGetUseCase.getFavoriteCount();
    const { count } = data.result.count;
    return count;
  };

  getCount = async (hasId: string, itemKey: string, setCount: (count: number) => void, getCountFromApi: () => Promise<number>) => {
    if (hasId === 'null' || !hasId) return;

    const countFromCache = this.getCountFromCache(itemKey);

    if (countFromCache !== null) {
      setCount(countFromCache);
      return;
    }

    try {
      const countFromApi = await getCountFromApi();
      const count = countFromApi || 0;
      window.sessionStorage.setItem(itemKey, JSON.stringify({ count }));
      setCount(count);
    } catch (e) {
      window.sessionStorage.setItem(itemKey, JSON.stringify({ count: 0 }));
      setCount(0);
    }
  };

  getBagCount = (setBagCount: (count: number) => void) => this.getCount(this.bagId, this.bagKey, setBagCount, this.getBagCountFromApi);

  getFavoriteCount = (setFavoriteCount: (count: number) => void) => this.getCount(this.clientId, this.favoriteKey, setFavoriteCount, this.getFavoriteCountFromApi);

  handleRedirectToFavoritePage() {
    if (isOnIframe() && window.parent && window.parent.location) {
      window.parent.location.href = `${this.baseUrl}/favoritos`;
    } else {
      window.location.href = `${this.baseUrl}/favoritos`;
    }
  }

  handleRedirectToLoginPage() {
    if (isOnIframe() && window.parent && window.parent.location) {
      window.parent.location.href = `${this.baseUrl}/checkout/login/favoritos`;
    } else {
      window.location.href = `${this.baseUrl}/checkout/login/favoritos`;
    }
  }

  handleFavoriteClickRedirectPage() {
    this.token && this.clientId ? this.handleRedirectToFavoritePage() : this.handleRedirectToLoginPage();
  }

  toggleHeader = (header: HTMLElement) => {
    const delta = 5;
    const navbarHeight = header.offsetHeight;
    const currentScroll = window.scrollY || document.documentElement.scrollTop;

    if (Math.abs(this.lastScrollTop - currentScroll) <= delta) return;

    if (currentScroll > this.lastScrollTop && currentScroll > navbarHeight) {
      header.classList.add('scrolling-down');
    } else {
      header.classList.remove('scrolling-down');
    }

    this.lastScrollTop = currentScroll;
  };

  addScrollEvent = (header: HTMLElement) => {
    window.addEventListener('scroll', debounce(this.toggleHeader(header), 300));
  }

  handleRedirectPageSearch = (value: string) => {
    const searchTerm = encodeURIComponent(value);

    if (isOnIframe() && window.parent && window.parent.location) {
      window.parent.location.href = `${this.baseUrl}/busca?q=${searchTerm}`;
    } else {
      window.location.href = `${this.baseUrl}/busca?q=${searchTerm}`;
    }
  };

  getSearchAutocomplete = async (
    value: string,
    setErrorSearch: (hasError: boolean) => void
  ) => {
    try {
      const historySize = 50;
      const termsSize = this.isMobile ? 3 : 4;
      const data = await this.headerSearchGetUseCase.getSearchAutocomplete(value, termsSize, historySize);
      setErrorSearch(false);
      this.setRecommendedList(data);
    } catch {
      setErrorSearch(true);
    }
  };

  renderRecommendedSearchTerms = (
    value: string,
    setHasError: (hasError: boolean) => void
  ) => {
    const hasSearchValue = value.length >= 3;

    if (!hasSearchValue) {
      this.setRecommendedList({ ...this.recommendedList, products: [] });
    }

    if (hasSearchValue || value.length === 0) {
      this.getSearchAutocomplete(value, setHasError);
    }
  };

  handleClickSearch = (
    event: Event,
    setHasError: (hasError: boolean) => void
  ) => {
    const value = (event.target as HTMLInputElement).value;
    this.setValueSearch(value);
    this.renderRecommendedSearchTerms(value, setHasError);
  };

  handleChangeSearchValue = (
    event: Event,
    setHasError: (hasError: boolean) => void
  ) => {
      const value = (event.target as HTMLInputElement).value
      this.setValueSearch(value);

      this.renderRecommendedSearchTerms(value, setHasError);
    }

  handleSubmitSearchValue = (event: Event) => {
    event.preventDefault();
    const headerSearch: HTMLInputElement = document.querySelector('#headerSearch')
    if (headerSearch.value.length < 2) {
      alert('Digite pelo menos 2 caracteres');
      return false;
    }

    this.handleRedirectPageSearch(headerSearch.value);
  };

  handleDeleteSearchHistory = async (historyId: string) => {
    const removedItemIndex = this.recommendedList.history.findIndex(item => item.id === historyId);
    this.recommendedList.history.splice(removedItemIndex, 1);

    try {
      await this.headerSearchDeleteUseCase.deleteSearchHistory(historyId);
      this.setRecommendedList({ ...this.recommendedList });
    } catch {
      this.setRecommendedList({ ...this.recommendedList });
    }
  };

  addEventListenerDeleteSearchHistory = () => {
    const dropdownOptions = document.querySelectorAll('ptz-header-search ptz-dropdown-option');

    dropdownOptions.forEach(options => {
      const iconDeleteHistory = options.querySelectorAll('.ptz-header-search-dropdown ptz-icon.ptz-dropdown-option-icon-right');

      iconDeleteHistory?.forEach(iconDelete => {
        iconDelete.addEventListener('click', event => {
          event.stopPropagation();
          const historyId = options.getAttribute('data-id');
          this.handleDeleteSearchHistory(historyId);
        });
      });
    });
  };

  getMenuDepartments = async (props: PTZHeaderTypes.getMenuDepartmentsType) => {
    try {
      const data = await this.headerMenuDepartmentsUseCase.getHeaderMenuDepartments();
      const mainData = data.filter(el => el.name !== 'Institucional');
      const formattedData = mainData.flatMap(obj => obj.departments);
      props.setMenuDepartments(formattedData);
      props.setMainMenuDepartments(formattedData.filter(el => (el.name !== 'Serviços' && el.name !== 'Assinatura')));
      props.setInstitutionalDepartments(data.find(el => el.name === 'Institucional').departments);
      props.setServicesDepartments(formattedData.find(el => el.name === 'Serviços').categories);
      props.setSalesDepartments(formattedData.find(el => el.name === 'Promoções').categories)
    } catch (error) {
      props.setMenuDepartments(dataDepartments);
      props.setMainMenuDepartments(mainDepartments);
      props.setInstitutionalDepartments(institutionalDepartments);
      props.setHasMenuError();
    }
  }

  getInitialMenuDrawerItems = (setInitialMenuDrawerItems: (menu: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[]) => void): void => {
    setInitialMenuDrawerItems([
      {
        isSectionTitle: true,
        name: 'Petz para Você',
        token: '',
      },
      {
        name: 'Assinatura Petz',
        token: 'https://landingpage.petz.com.br/assinatura-petz/',
      },
      {
        name: 'Ajuda',
        token: `${this.baseUrl}/atendimento-inteligente`,
      },
      {
        name: 'Lojas',
        token: `nossas-lojas`,
      },
      {
        name: 'Blog',
        token: `blog/`,
      },
      {
        name: 'Serviços',
        isNewItem: true,
        subCategories: [],
      },
      {
        isSectionTitle: true,
        name: 'Departamentos',
        token: '',
      },
      {
        isSectionTitle: true,
        name: 'Institucional',
        token: '',
      },
    ]);
  };
}
