import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, State, h, Watch } from '@stencil/core';
import { PTZHeaderDrawerBagTypes } from './components/ptz-header-drawer-bag/types/ptz-header-drawer-bag.types';
import { PTZHeaderIconNotificationConfig } from './components/ptz-header-icon-notification/types/ptz-header-icon-notification.enums';
import { PTZHeaderIconNotificationTypes } from './components/ptz-header-icon-notification/types/ptz-header-icon-notification.types';
import { PTZHeaderMenuBannersDrawerTypes } from './components/ptz-header-menu-banners-drawer/types/ptz-header-menu-banners-drawer.types';
import { PTZHeaderMenuDrawerTypes } from './components/ptz-header-menu-drawer/types/ptz-header-menu-drawer.types';
import HeaderPresenter from './ptz-header.presenter';
import { PTZHeaderConfig } from './types/ptz-header.enums';
import { PTZHeaderTypes } from './types/ptz-header.types';
import { PTZHeaderIconDropdownConfig } from './components/ptz-header-icon-dropdown/types/ptz-header-icon-dropdown.enums';

@Component({
  tag: 'ptz-header',
  styleUrl: 'ptz-header.scss',
  shadow: false,
})
export class PTZHeader {
  @Element() el: HTMLElement;
  private headerPresenter: HeaderPresenter;

  /** Abrir ou não a gaveta */
  @Prop({ mutable: true }) openDrawerBag: boolean = false;

  /** Flag para blackFriday */
  @Prop() blackFriday?: boolean = false;

  /** Valor da url base do ambiente */
  @Prop({ mutable: true }) baseUrl: string = '';

  /** Valor do token do usuário */
  @State() private _token: string = '';

  /** Valor do id do usuário */
  @State() private _clientId: string = '';

  /** Nome do usuário logado */
  @State() private _clientName: string = '';

  /** Valor do id da sacola do usuário */
  @State() private _bagId: string = '';

  /** Carregamento ativado ou não do header */
  @State() private _isLoaded: boolean = false;

  /** Quantidade de produto favoritado do usuário */
  @State() favoriteCount: number | null = null;

  /** Valor que está sendo digitado na busca */
  @State() valueSearch: string = '';

  /** Deve mostrar ou não o dropdown da busca */
  @State() showSearchDropdown: boolean = false;

  /** Lista de recomendação de produtos na busca */
  @State() recommendedSearchList: PTZHeaderTypes.SearchAPIResponse = { history: [], products: [], terms: [] };

  /** Quantidade de produto na sacola do usuário */
  @State() bagCount: number | null = null;

  /** Lista resumida de produtos na sacola */
  @State() bagSummaryList: PTZHeaderDrawerBagTypes.BagSummary;

    /** Deve mostrar ou não a gaveta */
  @State() openDrawer: PTZHeaderTypes.OpenDrawer | null;

  /** Informação sobre possíveis erro da gaveta */
  @State() hasErrorDrawer: PTZHeaderDrawerBagTypes.BagError | null;

  /** Carregamento ativado ou não da gaveta da sacola */
  @State() isLoadingDrawerBag: PTZHeaderDrawerBagTypes.BagLoad | null;

  /** Lista de departamento do menu */
  @State() menuDepartments:PTZHeaderTypes.MenuDepartments[] = [];

  /** Lista que inclui apenas principais departamentos  */
  @State() mainMenuDepartments:PTZHeaderTypes.MenuDepartments[] = [];

  /** Lista de departamentos de institucional  */
  @State() institutionalDepartments:PTZHeaderTypes.MenuDepartments[] = [];

  /** Lista de departamentos de serviços  */
  @State() servicesDepartments:PTZHeaderTypes.MenuDepartmentsCategories[] = [];

  /** Lista de departamentos de promoções  */
  @State() salesDepartments:PTZHeaderTypes.MenuDepartmentsCategories[] = [];

  /** Retorna booleano caso o menu apresente erro */
  @State() hasMenuError: boolean = false;

  /** Informação sobre a media query */
  @State() matchMedia: MediaQueryList;

  /** A versão da tela é mobile ou desktop */
  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`).matches;

  /** Guarda a lista inicial de itens do menu */
  @State() initialMenuDrawerItems: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[];

  /** Evento para trackeamento */
  @Event({
    eventName: 'analyticsTracking',
    composed: true,
  }) analyticsTracking: EventEmitter<PTZHeaderTypes.AnalyticsTracking>;

  /** Evento para carregar a notificação */
  @Event({
    eventName: 'loadIconNotification'
  }) loadIconNotification: EventEmitter<PTZHeaderIconNotificationTypes.LoadIconNotificationEvent>

  /** Evento disparado quando ocorre um erro de usuário não autorizado */
  @Event({
    eventName: 'tokenAuthError'
  }) tokenAuthError: EventEmitter

  private tokenAuthErrorHandler = () => {
    this.tokenAuthError.emit()
  }

  private analyticsTrackingHandler = (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => {
    this.analyticsTracking.emit({ type,...options });
  }

  private setOpenDrawer = (open?: boolean, kind?: PTZHeaderTypes.DrawerKind) => {
    const newType = kind || (this.bagCount > 0 ? PTZHeaderConfig.DrawerType.Bag : PTZHeaderConfig.DrawerType.BagEmpty);
    this.openDrawer = (open || !this.openDrawer) && { kind: newType };
  };

  private setIsLoadingBag = (data: PTZHeaderDrawerBagTypes.BagLoad | null) => {
    this.isLoadingDrawerBag = data && { type: data.type };
  };

  private setHasErrorDrawer = (data: PTZHeaderDrawerBagTypes.BagError | null) => {
    this.hasErrorDrawer = data && { type: data.type, sku: data.sku };
  };

  private setBagCount = (count: number) => {
    this.bagCount = count;
  };

  private setFavoriteCount = (count: number) => {
    this.favoriteCount = count;
  };

  private setValueSearch = (value: string) => {
    this.valueSearch = value;
  };

  private setBagSummaryList = (list: PTZHeaderDrawerBagTypes.BagSummary) => {
    this.bagSummaryList = list;
  };

  private setRecommendedSearchList = (list: PTZHeaderTypes.SearchAPIResponse) => {
    this.recommendedSearchList = list;
  };

  private setMenuDepartments = (menu: PTZHeaderTypes.MenuDepartments[]) => {
    this.menuDepartments = menu;
  };

  private setMainMenuDepartments = (menu: PTZHeaderTypes.MenuDepartments[]) => {
    this.mainMenuDepartments = menu;
  };

  private setInstitutionalDepartments = (menu: PTZHeaderTypes.MenuDepartments[]) => {
    this.institutionalDepartments = menu;
  };

  private setServicesDepartments = (menu: PTZHeaderTypes.MenuDepartmentsCategories[]) => {
    this.servicesDepartments = menu;
  };

  private setSalesDepartments = (menu: PTZHeaderTypes.MenuDepartmentsCategories[]) => {
    this.salesDepartments = menu;
  };

  private setHasMenuError = () => {
    this.hasMenuError = true;
  }

  private setInitialMenuDrawerItems = (menu: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[]) => {
    this.initialMenuDrawerItems = menu;
  };

  private async handleMenuDepartments() {
    this._isLoaded = true;
    const props = {
      setMenuDepartments: this.setMenuDepartments,
      setMainMenuDepartments: this.setMainMenuDepartments,
      setInstitutionalDepartments: this.setInstitutionalDepartments,
      setServicesDepartments: this.setServicesDepartments,
      setSalesDepartments: this.setSalesDepartments,
      setHasMenuError: this.setHasMenuError
    };
    this.headerPresenter.getInitialMenuDrawerItems(this.setInitialMenuDrawerItems);
    await this.headerPresenter.getMenuDepartments(props);
  }

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  @Method()
  async config(obj: PTZHeaderTypes.Config) {
    this.baseUrl = obj.baseUrl;
    this._clientId = obj.clientId;
    this._clientName = obj.clientName;
    this._token = obj.token;
    this._bagId = obj.bagId;
  }

  @Watch("_token")
  @Watch("baseUrl")
  @Watch("_clientId")
  watchClientIdValue() {
    if (this._clientId) {
      this.headerPresenter.getMethodConfigValues(this._token, this._bagId, this._clientId, this.baseUrl)
      this.loadGetCountNotification('favorite')
    }
  }

  @Watch("_token")
  @Watch("_bagId")
  @Watch("baseUrl")
  watchBagIdValue() {
    if (this._bagId) {
      this.headerPresenter.getMethodConfigValues(this._token, this._bagId, this._clientId, this.baseUrl)
      this.loadGetCountNotification('bag')
    }
  }

  @Listen('menuItemClick')
  menuOpenDrawerClick(event: CustomEvent) {
    const label = event.detail;
    let menuType;
    if (label === 'Serviços') {
      menuType = PTZHeaderConfig.DrawerType.ServicesMenu;
      this.setOpenDrawer(true, menuType);
    }
    if (label === 'Promoções') {
      menuType = PTZHeaderConfig.DrawerType.SalesMenu;
      this.setOpenDrawer(true, menuType);
    }

  }

  @Listen('scroll', { target: 'window' })
  handlerScroll() {
    this.headerPresenter.addScrollEvent(this.el);
  }

  @Listen('loadIconNotification', { target: 'document' })
  getCountNotification(event: CustomEvent<PTZHeaderIconNotificationTypes.LoadIconNotificationEvent>) {
    if (!this._token) return;

    const { kind } = event.detail
    if (kind === PTZHeaderIconNotificationConfig.Kind.Bag && this._bagId) {
      this.headerPresenter.getBagCount(this.setBagCount);
    }
      if (kind === PTZHeaderIconNotificationConfig.Kind.Favorite && this._clientId) {
      this.headerPresenter.getFavoriteCount(this.setFavoriteCount);
    }
  }

  @Listen('toggleDrawer')
  toggleDrawer(event: CustomEvent) {
    if (event?.detail !== undefined) {
      this.setOpenDrawer(event?.detail);
    }
  }

  loadGetCountNotification = (kind: PTZHeaderIconNotificationTypes.Kind) => {
    const eventCount = new CustomEvent('loadIconNotification', {
      detail: {
        kind
      } as PTZHeaderIconNotificationTypes.LoadIconNotificationEvent
    });

    this.getCountNotification(eventCount);
  }

  componentWillRender() {
    this.headerPresenter = new HeaderPresenter(
      this.baseUrl,
      this._clientId,
      this._bagId,
      this._token,
      this.isMobile,
      this.setValueSearch,
      this.recommendedSearchList,
      this.setRecommendedSearchList,
      this.setBagSummaryList,
      this.setHasErrorDrawer,
      this.setIsLoadingBag,
      this.analyticsTrackingHandler,
      this.tokenAuthErrorHandler,
    );
  }

  componentDidRender() {
    this.headerPresenter.addEventListenerDeleteSearchHistory();
  }

  componentDidUpdate() {
    if (!this._isLoaded) {
      this.handleMenuDepartments();
    }

    document.body.style.overflow = this.openDrawer ? 'hidden' : 'auto';
  }

  componentWillLoad() {
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
  }


  render() {
    const kind = this.openDrawer?.kind;
    const openDrawerEmptyBag = this.openDrawer && kind === PTZHeaderConfig.DrawerType.BagEmpty;
    const openDrawerBag = this.openDrawer && kind === PTZHeaderConfig.DrawerType.Bag;
    const openDrawerSalesMenuOrServiceMenu = !!(kind === PTZHeaderConfig.DrawerType.SalesMenu || kind === PTZHeaderConfig.DrawerType.ServicesMenu);
    const openDrawerMainMenu = this.openDrawer && this.openDrawer.kind === 'mainMenu';
    const drawerType = this.openDrawerBag ? 'added' : 'confirm';

    return (
      <Host>
        <header class="ptz-header">
          <div class="ptz-header-wrapper">
            <div class="ptz-header-content">
              <div class="ptz-header-logo">
                <slot name="ptz-header-logo-petz" />
              </div>
              <ptz-header-search
                baseUrl={this.baseUrl}
                value={this.valueSearch}
                isMobile={this.isMobile}
                blackFriday={this.blackFriday}
                showDropdown={this.showSearchDropdown}
                recommendedList={this.recommendedSearchList}
                clickHandler={this.headerPresenter.handleClickSearch}
                analyticsTrackingHandler={this.analyticsTrackingHandler}
                changeValueHandler={this.headerPresenter.handleChangeSearchValue}
                redirectPageHandler={this.headerPresenter.handleRedirectPageSearch}
                submitSearchValueHandler={this.headerPresenter.handleSubmitSearchValue}
              />
              <ptz-header-icon-dropdown
                iconTitle={'Ajuda'}
                baseUrl={this.baseUrl}
                kind={PTZHeaderIconDropdownConfig.Kind.Help}
                analyticsTrackingHandler={this.analyticsTrackingHandler}
                iconName={PTZHeaderIconDropdownConfig.IconName.Phone}
              />
              <ptz-header-icon-notification
                iconTitle={'Favoritos'}
                amount={!!this._token ? this.favoriteCount : 0}
                iconUrlRedirect={`${this.baseUrl}/favoritos`}
                kind={PTZHeaderIconNotificationConfig.Kind.Favorite}
                analyticsTrackingHandler={this.analyticsTrackingHandler}
                iconName={PTZHeaderIconNotificationConfig.IconName.Heart}
                clickHandler={() => this.headerPresenter.handleFavoriteClickRedirectPage()}
              />
              <ptz-header-icon-notification
                iconTitle={'Sacola'}
                amount={!!this._token ? this.bagCount : 0}
                clickHandler={() => this.setOpenDrawer(true)}
                kind={PTZHeaderIconNotificationConfig.Kind.Bag}
                analyticsTrackingHandler={this.analyticsTrackingHandler}
                iconName={PTZHeaderIconNotificationConfig.IconName.PetzBag}
              />
              <ptz-header-hamburger onClickHandler={() => this.setOpenDrawer(true, 'mainMenu')} />
              <ptz-header-profile
                baseUrl={this.baseUrl}
                clientId={this._clientId}
                clientToken={this._token}
                clientName={this._clientName}
                analyticsTrackingHandler={this.analyticsTrackingHandler}
              />
            </div>
          </div>
          <ptz-header-departments analyticsTrackingHandler={this.analyticsTrackingHandler} menuData={this.menuDepartments} hasError={this.hasMenuError}></ptz-header-departments>
        </header>
        <ptz-header-drawer-bag
          type={drawerType}
          bagId={this._bagId}
          open={openDrawerBag}
          baseUrl={this.baseUrl}
          isMobile={this.isMobile}
          hasError={this.hasErrorDrawer}
          bagSummary={this.bagSummaryList}
          isLoading={this.isLoadingDrawerBag}
          setIsLoadingBag={this.setIsLoadingBag}
          footerButtonClickHandler={this.setOpenDrawer}
          analyticsTrackingHandler={this.analyticsTrackingHandler}
          updateProductHandler={this.headerPresenter.postBagProduct}
          getSummaryListHandler={this.headerPresenter.getBagSummary}
          deleteProductHandler={this.headerPresenter.deleteBagProduct}
        />
        <ptz-header-drawer-bag-empty open={openDrawerEmptyBag} isMobile={this.isMobile} footerButtonClickHandler={this.setOpenDrawer} />
        <ptz-header-menu-drawer
          baseUrl={this.baseUrl}
          isMobile={this.isMobile}
          clientId={this._clientId}
          clientToken={this._token}
          open={openDrawerMainMenu}
          clientName={this._clientName}
          hasMenuError={this.hasMenuError}
          salesDepartments={this.salesDepartments}
          mainMenuDepartments={this.mainMenuDepartments}
          servicesDepartments={this.servicesDepartments}
          initialMenuDrawerItems={this.initialMenuDrawerItems}
          analyticsTrackingHandler={this.analyticsTrackingHandler}
          institutionalDepartments={this.institutionalDepartments}
        />
        <ptz-header-menu-banners-drawer
          baseUrl={this.baseUrl}
          hasError={this.hasMenuError}
          open={openDrawerSalesMenuOrServiceMenu}
          salesDepartmentsData={this.salesDepartments}
          servicesDepartmentsData={this.servicesDepartments}
          analyticsTrackingHandler={this.analyticsTrackingHandler}
          kind={kind as PTZHeaderMenuBannersDrawerTypes.IsKind<typeof kind>}
        />
      </Host>
    );
  }
}
