import { HeaderSearchRepositoryInterface } from '../../../data/repositories/ptz-header-search/ptz-header-search.repository';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

class GetHeaderSearchUseCase {
    constructor(
      private readonly baseUrl: string,
      private readonly clientId: string,
      private readonly token: string,
      private readonly repository: HeaderSearchRepositoryInterface,
      private readonly tokenAuthErrorHandler: () => void,
    ) {}

    async getSearchAutocomplete(
      value: string,
      termsSize?: number,
      historySize?: number,
      productsSize?: number,
    ): Promise<PTZHeaderTypes.SearchAPIResponse> {
      return await this.repository.getSearchAutocomplete({
        value,
        termsSize,
        historySize,
        productsSize,
        baseUrl: this.baseUrl,
        clientId: this.clientId,
        token: this.token,
        tokenAuthErrorHandler: this.tokenAuthErrorHandler,
      });
    }
}

export default GetHeaderSearchUseCase;
