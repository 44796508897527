import { PTZHeaderTypes } from '../../../types/ptz-header.types';
import { HeaderFavoriteRepositoryInterface } from '../../../data/repositories/ptz-header-favorite/ptz-header-favorite.repository';

class GetHeaderFavoriteUseCase {
    constructor(
      private readonly baseUrl: string,
      private readonly clientId: string,
      private readonly token: string,
      private readonly repository: HeaderFavoriteRepositoryInterface,
      private readonly tokenAuthErrorHandler: () => void,
    ) {}

    async getFavoriteCount(): Promise<PTZHeaderTypes.FavoriteAPIResponse> {
      return await this.repository.getFavoriteCount({
        baseUrl: this.baseUrl,
        clientId: this.clientId,
        token: this.token,
        tokenAuthErrorHandler: this.tokenAuthErrorHandler,
      });
    }
}

export default GetHeaderFavoriteUseCase;
