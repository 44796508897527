ptz-header {
  .ptz-header {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 997;
    height: auto;
    display: block;
    position: relative;
    box-sizing: border-box;

    &-logo {
      margin-right: 9.25rem;
    }

    &-wrapper {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: $petz-color-neutral-white;
    }

    &-content {
      display: flex;
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: center;
      max-width: $petz-breakpoint-2xl;
      padding: $petz-spacing-xs $petz-spacing-2xl;
      padding-bottom: 0;
    }
  }

  & .menu-departments {
    transform: translateY(-1px);
    transition: transform 0.4s ease-out;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    border-bottom: $petz-border-sm solid $petz-color-neutral-mid;
  }

  &.scrolling-down {
    ptz-header-departments {
      .menu-departments {
        position: absolute;
        transform: translateY(-99%);
        left: 0;
        right: 0;
        z-index: -1;
        transition: transform 0.4s ease;

        * {
          pointer-events: none;
          opacity: 0;
          transition: all 0.6s ease;
        }
      }
    }

    .ptz-header-content {
      padding-bottom: $petz-spacing-sm;
    }
  }

  ptz-header-drawer-bag,
  ptz-header-menu-drawer,
  ptz-header-drawer-bag-empty,
  ptz-header-menu-banners-drawer {
    ptz-drawer {
      display: none;
    }
  }
}

@media screen and (min-width: $petz-breakpoint-xl) {
  ptz-header {
    .ptz-header {
      position: fixed;

      &-content {
        justify-content: space-between;
        padding: $petz-spacing-sm $petz-spacing-2xl;
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header {
    .ptz-header {
      border-bottom: $petz-border-sm solid $petz-color-neutral-mid;

      &-logo {
        margin-right: 0;
      }

      &-content {
        flex-direction: column;
        padding: $petz-spacing-sm $petz-spacing-lg;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-sm) {
  ptz-header {
    .ptz-header {
      &-content {
        padding: $petz-spacing-md $petz-spacing-sm;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-header {
    .ptz-header {
      &-logo {
        padding-top: $petz-spacing-2xs;
      }
    }
  }
}
