import { HeaderBagRepositoryInterface } from '../../../data/repositories/ptz-header-bag/ptz-header-bag.repository';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

class PostHeaderBagUseCase {
  constructor(
    private readonly baseUrl: string,
    private readonly bagId: string,
    private readonly token: string,
    private readonly repository: HeaderBagRepositoryInterface,
    private readonly tokenAuthErrorHandler: () => void,
  ) {}

  async postBagProduct(sku: string, quantity: number): Promise<PTZHeaderTypes.BagProductAPIResponse> {
    return await this.repository.postBagProduct({
      baseUrl: this.baseUrl,
      token: this.token,
      bagId: this.bagId,
      sku,
      quantity,
      tokenAuthErrorHandler: this.tokenAuthErrorHandler,
    });
  }
}

export default PostHeaderBagUseCase;
