import HttpClient, { HttpClientInterface } from '../../../../../infra/http-client/HTTPClient';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';


export interface HeaderFavoriteRepositoryInterface {
  getFavoriteCount: (search: PTZHeaderTypes.FavoriteGetRequest) => Promise<PTZHeaderTypes.FavoriteAPIResponse>;
}

class HeaderFavoriteRepository implements HeaderFavoriteRepositoryInterface {
  private http: HttpClientInterface<PTZHeaderTypes.FavoriteAPIResponse | null> = new HttpClient();
  private fixedHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  getFavoriteCount = async ({
    baseUrl,
    clientId,
    token,
    tokenAuthErrorHandler,
  }: PTZHeaderTypes.FavoriteGetRequest): Promise<PTZHeaderTypes.FavoriteAPIResponse> => {
    const url = `${baseUrl}/api/v3/client/${clientId}/favoriteProduct/count`;

    const tokenJson = token ? JSON.parse(decodeURIComponent(token)) : null;
    let xAuthorization = tokenJson?.access_token ?  { "X-Authorization": "Bearer " + tokenJson.access_token } : {};
    const headers = { ...this.fixedHeaders, ...xAuthorization };

    let response: PTZHeaderTypes.FavoriteAPIResponse;
    try {
      response = await this.http.request({ url, headers });
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        tokenAuthErrorHandler()
      }
      throw error;
    }

    return response;
  };
}

export default HeaderFavoriteRepository;
