import HttpClient, { HttpClientInterface } from '../../../../../infra/http-client/HTTPClient';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

export interface HeaderMenuDepartmentsInterface {
    getMenuDepartments: (baseUrl: string) => Promise<PTZHeaderTypes.MenuDepartmentsAPIResponse[]>;
}

class HeaderMenuDepartmentsRepository implements HeaderMenuDepartmentsInterface {
    private http: HttpClientInterface<PTZHeaderTypes.MenuDepartmentsAPIResponse[] | null> = new HttpClient();
    getMenuDepartments = async (baseUrl: string) => {
        const url = `${baseUrl}/api/v3/public/menu/departments`;
        let response: PTZHeaderTypes.MenuDepartmentsAPIResponse[];
        try {
          response = await this.http.request({ url });
        } catch (error) {
          throw error;
        }
        return response;
    }
}

export default HeaderMenuDepartmentsRepository;