import { HeaderSearchRepositoryInterface } from '../../../data/repositories/ptz-header-search/ptz-header-search.repository';

class DeleteHeaderSearchUseCase {
    constructor(
      private readonly baseUrl: string,
      private readonly token: string,
      private readonly repository: HeaderSearchRepositoryInterface,
      private readonly tokenAuthErrorHandler: () => void,
    ) {}

    async deleteSearchHistory(
      historyId: string,
    ): Promise<null> {
      return await this.repository.deleteSearchHistory({
        historyId,
        baseUrl: this.baseUrl,
        token: this.token,
        tokenAuthErrorHandler: this.tokenAuthErrorHandler,
      });
    }
}

export default DeleteHeaderSearchUseCase;
