import { HeaderBagRepositoryInterface } from '../../../data/repositories/ptz-header-bag/ptz-header-bag.repository';
import { PTZHeaderTypes } from '../../../types/ptz-header.types';

class GetHeaderBagUseCase {
  constructor(
    private readonly baseUrl: string,
    private readonly bagId: string,
    private readonly token: string,
    private readonly repository: HeaderBagRepositoryInterface,
    private readonly tokenAuthErrorHandler: () => void,
  ) {}

  async getBagCount(): Promise<PTZHeaderTypes.BagCountAPIResponse> {
    return await this.repository.getBagCount({
      baseUrl: this.baseUrl,
      token: this.token,
      bagId: this.bagId,
      tokenAuthErrorHandler: this.tokenAuthErrorHandler,
    });
  }

  async getBagSummary(): Promise<PTZHeaderTypes.BagSummaryAPIResponse> {
    return await this.repository.getBagSummary({
      baseUrl: this.baseUrl,
      token: this.token,
      bagId: this.bagId,
      tokenAuthErrorHandler: this.tokenAuthErrorHandler,
    });
  }
}

export default GetHeaderBagUseCase;
