import { EnumToArray } from '../../../../../utils/enumToArray';

enum Kind {
  Help = 'help'
}

enum IconName {
  Phone = 'phone'
}

export const PTZHeaderIconDropdownConfigList = {
  Kind: EnumToArray([Kind]),
  IconName: EnumToArray([IconName]),
};

export const PTZHeaderIconDropdownConfig = {
  Kind,
  IconName
};
