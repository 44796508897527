export const mainDepartments = [
  {
    id: 1,
    name: "Cachorro",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/dog.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/cachorro",
    color: "#E8604B",
    categories: []
  },
  {
    id: 2,
    name: "Gato",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/cat.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/gato",
    color: "#D19F72",
    categories: []
  },
  {
    id: 3,
    name: "Pássaro",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/bird.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/passaros",
    color: "#DD6288",
    categories: []
  },
  {
    id: 4,
    name: "Peixe",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/fish.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/peixes",
    color: "#8E7FAE",
    categories: []
  },
  {
    id: 5,
    name: "Outros Pets",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/reptile.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/outros-pets",
    color: "#71B790",
    categories: []
  },
  {
    id: 6,
    name: "Casa e Jardim",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/cactus.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/casa-e-jardim",
    color: "#999C5F",
    categories: []
  },
  {
    id: 8,
    name: "Promoções",
    urlImage: "",
    urlImageStatic: "https://static-file.petz.com.br/platforms/img/product/ecommerce/outlet.svg",
    urlImageMobile: "",
    token: "https://www.petz.com.br/promocoes",
    color: "#EB4A3F",
    categories: []
  },
];

export const dataDepartments = [
  {
    id: 1,
    name: "Cachorro",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/dog.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/cachorro",
    color: "#E8604B",
  },
  {
    id: 2,
    name: "Gato",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/cat.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/gato",
    color: "#D19F72",
  },
  {
    id: 3,
    name: "Pássaro",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/bird.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/passaros",
    color: "#DD6288",
  },
  {
    id: 4,
    name: "Peixe",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/fish.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/peixes",
    color: "#8E7FAE",
  },
  {
    id: 5,
    name: "Outros Pets",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/reptile.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/outros-pets",
    color: "#71B790",
  },
  {
    id: 6,
    name: "Casa e Jardim",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/cactus.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/casa-e-jardim",
    color: "#999C5F",
  },
  {
    id: 7,
    name: "Serviços",
    urlImage: "",
    urlImageStatic: "https://static-file.petz.com.br/platforms/img/product/ecommerce/side-menu.svg",
    urlImageMobile: "",
    token: "",
    color: "#1D1D1D",
  },
  {
    id: 8,
    name: "Promoções",
    urlImage: "",
    urlImageStatic: "https://static-file.petz.com.br/platforms/img/product/ecommerce/outlet.svg",
    urlImageMobile: "",
    token: "https://www.petz.com.br/promocoes",
    color: "#EB4A3F",
  },
  {
    id: 9,
    name: "Assinatura",
    urlImage: "",
    urlImageStatic: "https://static-file.petz.com.br/platforms/img/product/ecommerce/subscription.svg",
    urlImageMobile: "",
    token: "https://landingpage.petz.com.br/assinatura-petz/",
    color: "#175EA8",
  },
];

export const institutionalDepartments = [
  {
    id: 11,
    name: "Institucional",
    urlImage: "",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "",
    color: "",
  },
  {
    id: 13,
    name: "Nossas Políticas",
    urlImage: "",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "",
    color: "",
  },
  {
    id: 12,
    name: "Aqui você encontra",
    urlImage: "",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "",
    color: "",
  },
];

export const selectedCategoryMock = [
  {
    id: 408,
    name: "Cão Cidadão",
    token: "https://landingpage.petz.com.br/adestramento/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-mobile.svg",
    color: "",
  },
  {
    id: 409,
    name: "Cursos Online Dr. Pet",
    token: "https://landingpage.petz.com.br/dr-pet/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-mobile.svg",
    color: "",
  },
];

export const categorySaleListMock = [
  {
    id: 56,
    name: "Promoção compre 1 leve 2",
    token: "promoções",
    urlImage: "ptz-header-menu-banner-sale.svg",
    urlImageMobile: "ptz-header-menu-banner-sale.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 57,
    name: "Promoção compre 2 leve 3",
    token: "promoções",
    urlImage: "ptz-header-menu-banner-sale.svg",
    urlImageMobile: "ptz-header-menu-banner-sale.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 58,
    name: "50% de desconto",
    token: "promoções",
    urlImage: "ptz-header-menu-banner-sale.svg",
    urlImageMobile: "ptz-header-menu-banner-sale.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 59,
    name: "15% de desconto",
    token: "promoções",
    urlImage: "ptz-header-menu-banner-sale.svg",
    urlImageMobile: "ptz-header-menu-banner-sale.svg",
    color: "",
    subCategories: [],
  },
];

export const categoryServiceListMock = [
  {
    id: 56,
    name: "Veterinário",
    token: "https://seres.vet/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/seres-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/seres-banner-mobile.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 57,
    name: "Banho&Tosa",
    token: "https://landingpage.petz.com.br/banho-e-tosa/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/banho-e-tosa-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/banho-e-tosa-banner-mobile.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 58,
    name: "Adotepetz",
    token: "https://www.adotepetz.com.br/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/adotepetz-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/adotepetz-banner-mobile.svg",
    color: "",
    subCategories: [],
  },
  {
    id: 59,
    name: "Adestramento",
    token: "/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/training-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/training-banner-mobile.svg",
    color: "",
    subCategories: [
      {
        id: 408,
        name: "Cão Cidadão",
        token: "https://landingpage.petz.com.br/adestramento/",
        urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-desktop.svg",
        urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-mobile.svg",
        color: "",
      },
      {
        id: 409,
        name: "Cursos Online Dr. Pet",
        token: "https://landingpage.petz.com.br/dr-pet/",
        urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-desktop.svg",
        urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-mobile.svg",
        color: "",
      },
    ],
  },
];

export const selectedCategoryServiceMock = [
  {
    id: 408,
    name: "Cão Cidadão",
    token: "https://landingpage.petz.com.br/adestramento/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/cao-cidadao-banner-mobile.svg",
    color: "",
  },
  {
    id: 409,
    name: "Cursos Online Dr. Pet",
    token: "https://landingpage.petz.com.br/dr-pet/",
    urlImage: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-desktop.svg",
    urlImageMobile: "https://dev-static.labpetz.com.br/platforms/img/product/ecommerce/services-menu/dr-pet-banner-mobile.svg",
    color: "",
  },
];

export const categoriesMock = [
  {
    id: 1,
    name: "Ração",
    urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/dog.json",
    urlImageStatic: "",
    urlImageMobile: "",
    token: "https://www.petz.com.br/cachorro/ração",
    color: "#E8604B",
    subCategories: [
      {
        id: 2,
        name: "Ração seca",
        urlImage: "https://static-file.petz.com.br/platforms/img/product/superapp/lottie/dog.json",
        urlImageStatic: "",
        urlImageMobile: "",
        token: "https://www.petz.com.br/cachorro/ração/ração-seca",
        color: "#E8604B",
      },
    ],
  },
];

export const initialMenuDrawerItemsMock = [
  {
    isSectionTitle: true,
    name: 'Petz para Você',
    token: '',
  },
  {
    name: 'Assinatura Petz',
    token: 'https://landingpage.petz.com.br/assinatura-petz/',
  },
  {
    name: 'Ajuda',
    token: `https://www.petz.com.br/atendimento-inteligente`,
  },
  {
    name: 'Lojas',
    token: `nossas-lojas`,
  },
  {
    name: 'Blog',
    token: `blog/`,
  },
  {
    name: 'Serviços',
    isNewItem: true,
    subCategories: [],
  },
  {
    isSectionTitle: true,
    name: 'Departamentos',
    token: '',
  },
  {
    isSectionTitle: true,
    name: 'Institucional',
    token: '',
  },
]
