import { HeaderMenuDepartmentsInterface } from "../../../data/repositories/ptz-header-menu-departments/ptz-header-menu-departments.repository";
import { PTZHeaderTypes } from "../../../types/ptz-header.types";

class GetHeaderMenuDepartments {
    constructor(
        private readonly baseUrl: string,
        private readonly repository: HeaderMenuDepartmentsInterface,
    ) {}

    async getHeaderMenuDepartments(): Promise<PTZHeaderTypes.MenuDepartmentsAPIResponse[]> {
        return await this.repository.getMenuDepartments(this.baseUrl);
    }
}

export default GetHeaderMenuDepartments;