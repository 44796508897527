import { EnumToArray } from '../../../../../utils/enumToArray';

enum Kind {
  Bag = 'bag',
  Favorite = 'favorite',
}

enum IconName {
  PetzBag = 'petz-bag',
  Heart = 'heart',
}

export const PTZHeaderIconNotificationConfigList = {
  Kind: EnumToArray([Kind]),
  IconName: EnumToArray([IconName]),
};

export const PTZHeaderIconNotificationConfig = {
  Kind,
  IconName
};
